.container {
  position: relative;
  background-color: #e2001a;
  color: white;
  overflow: hidden;
}

.background {
  position: absolute;
  z-index: 0;
  top: -50vh;
  left: -50vw;
  height: 200vh;
  width: 200vw;
  transform: rotate(45deg);
  background-image: url("./assets/tile.svg");
  background-repeat: repeat;
  background-size: 70%;
  background-position-x: -2.5rem;
  opacity: 0.5;
}

.header {
  position: relative;
  z-index: 1;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
}

.logo {
  width: 50vmin;
  pointer-events: none;
  margin-bottom: 3rem;
}

.badge:first-of-type {
  margin-bottom: 1rem;
}

.badge img {
  width: 50vmin;
}

.unavailable {
  pointer-events: none;
  opacity: 0.5;
}

.hint {
  max-width: 80vmin;
  text-align: center;
}